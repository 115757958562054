import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import { AdStarBlock, Category, GeneralSettings } from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import { AWARDS_COUNTY_INFO } from '../../lib/data/Awards'
import { generateLink, AssetType } from '../../templates/awards-assets-template'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
import TideB2B from '../../components/MuddyAwards/TideB2B/TideB2B'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../lib/utils'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsFAQProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      category: Category
    }
  }
}

const AwardsGetInvolved = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
    }
  }
}: AwardsFAQProps) => {
  const AwardsContext = useContext(MuddyAwardsContext)
  const countyInfo = generalSettings.siteId
    ? AWARDS_COUNTY_INFO.filter(c => c.siteId === generalSettings.siteId)[0]
    : AWARDS_COUNTY_INFO[0]

  let socialImage = {
    img: `https://dev.muddystilettos.co.uk/awards-2024/assets/winners/square/Winner24_london_square.mp4`,
    type: 'mp4'
  }

  if (countyInfo?.county)
    socialImage = {
      img: generateLink({
        county: countyInfo.siteSlug,
        type: AssetType.WINNER_SQUARE
      }),
      type: 'mp4'
    }

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ Get Involved'}
            postURI={'/awards/get-involved'}
          />
          <h1 dangerouslySetInnerHTML={{ __html: 'How To Get Involved' }} />
          <p>&nbsp;</p>
          <figure>
            <img
              src={
                'http://dev.muddystilettos.co.uk/wp-content/uploads/2024/02/getinvolved2-min-scaled.jpg'
              }
            />
          </figure>
          <>
            <p
              className="has-text-color"
              style={{
                color: '#d41a50',
                textAlign: 'center',
                fontWeight: 'bold'
              }}
            >
              <strong>
                {AwardsContext === MuddyAwardsStates.VOTING_CLOSED ? (
                  'ARE YOU A WINNER?'
                ) : (
                  <>
                    {AwardsContext === MuddyAwardsStates.VOTING
                      ? 'ARE YOU A FINALIST?'
                      : AwardsContext === MuddyAwardsStates.WINNERS
                      ? 'THE RESULTS ARE IN'
                      : 'NEXT STEPS FOR NOMINEES'}
                  </>
                )}
              </strong>
            </p>

            {AwardsContext === MuddyAwardsStates.VOTING_CLOSED ? (
              <>
                <p style={{ fontSize: '14px', textAlign: 'center' }}>
                  THE MUDDY REGIONAL AWARDS VOTING IS NOW CLOSED
                </p>
              </>
            ) : AwardsContext === MuddyAwardsStates.WINNERS ? (
              <></>
            ) : (
              <p>
                The Muddy Stilettos Awards are renowned for their quality – we
                celebrate the most exciting independent businesses in{' '}
                {countyInfo?.county ?? 'the UK'}, so to win a Muddy Award is a
                real mark of distinction.
              </p>
            )}

            {AwardsContext === MuddyAwardsStates.WINNERS ? (
              <>
                <p>
                  Congratulations to all the winners - and cue a chorus of
                  Champagne corks popping across the county! The Muddy Stilettos
                  Awards are renowned for their quality – we celebrate the most
                  exciting independent businesses across 28 counties so to win a
                  Muddy Award is a mark of distinction and real kudos.{' '}
                </p>
                <p>
                  All the Winners will be contacted by Muddy Stilettos via email
                  by 5pm, Thursday 02 May 2024.{' '}
                  {generalSettings.siteId !== 24 && (
                    <>
                      You will also be invited to Winner’s drinks to celebrate
                      with a glass of bubbly and to collect your sparkly
                      Winner’s certificate.{' '}
                    </>
                  )}
                </p>
                <p>
                  Thanks to all of you who have taken part, either as readers or
                  businesses.
                </p>
              </>
            ) : (
              <>
                {AwardsContext === MuddyAwardsStates.VOTING_CLOSED ? (
                  <>
                    <p>
                      Thank you to all of you who have taken part, either as
                      readers or businesses.
                    </p>
                    <p>
                      All the Winners will be contacted by Muddy Stilettos via
                      email by 5pm, Wednesday 02 May 2024.
                    </p>
                  </>
                ) : (
                  <>
                    {AwardsContext === MuddyAwardsStates.VOTING ? (
                      <>
                        <p>
                          All the Finalists will be contacted by Muddy Stilettos
                          via email by 5pm, Wednesday 03 April 2024.
                        </p>
                        <p>If you’ve made it to the Finals, congratulations!</p>
                      </>
                    ) : (
                      <p>
                        If you get nominated (nice work), feel free to shout
                        about it! The top five businesses with the most
                        nominations in each category will make it to the
                        Regional Finals.
                      </p>
                    )}
                  </>
                )}
              </>
            )}

            <p>
              <strong className="has-text-color" style={{ color: '#d41a50' }}>
                WHAT TO DO NEXT
              </strong>
              <br />
              {AwardsContext === MuddyAwardsStates.VOTING_CLOSED
                ? `Whether Finalist or Regional Winner, it's a real achievement and it deserves to be rewarded.`
                : AwardsContext === MuddyAwardsStates.WINNERS
                ? `To be a Regional Winner or a Finalist is a real achievement and it deserves to be rewarded.`
                : `
                 The Muddy Awards offer a great marketing opportunity to raise your profile and promote your business to existing and new customers.
                 `}
            </p>
            <p>
              We’ve prepared some assets to help you celebrate your success and
              share it with your customers and friends.
            </p>

            {socialImage && (
              <>
                {socialImage.img && socialImage.type === 'img' ? (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      style={{ maxWidth: '300px', margin: '0 auto' }}
                      src={socialImage.img}
                    />
                    <p style={{ fontSize: '14px' }}>
                      Sample asset found on the{' '}
                      <Link to={'/awards/assets'}>Assets</Link> page
                    </p>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <video
                      loop
                      width={'300px'}
                      controls={false}
                      autoPlay={true}
                      muted
                    >
                      <source type={'video/mp4'} src={socialImage.img} />
                    </video>
                    <p style={{ fontSize: '14px' }}>
                      Sample asset found on the{' '}
                      <Link to={'/awards/assets'}>Assets</Link> page
                    </p>
                  </div>
                )}
              </>
            )}
            <p>
              <strong style={{ color: 'rgb(231, 44, 94)' }}>1.</strong> Head to
              the Assets tab to find downloadable assets for{' '}
              <strong>your website or social media</strong>. (A password will
              have been emailed directly to you).
              <br />
              <strong style={{ color: 'rgb(231, 44, 94)' }}>2.</strong> Select
              if you are a Winner or a Finalist, your county and category and
              simply download your files. <br />
              <strong style={{ color: 'rgb(231, 44, 94)' }}>3.</strong> You can
              choose our in-house assets or create your own social assets with
              our Muddy Awards 2024 logo.
              <br />
              <strong style={{ color: 'rgb(231, 44, 94)' }}>4.</strong> Don’t
              forget to tag @{countyInfo?.socialHandle ?? 'muddystilettos'} in
              your posts and use our hashtags #MuddyAwards2024 and
              #InLoveWithLocal.{' '}
            </p>

            <figure>
              <img
                src={
                  'http://dev.muddystilettos.co.uk/wp-content/uploads/2024/02/getinvolved1-min-scaled.jpg'
                }
              />
            </figure>

            <p>
              <strong style={{ color: 'rgb(231, 44, 94)' }}>
                KINDNESS RULES!
              </strong>
              <br />
              We run the Muddy Awards from a place of warmth and love for all
              things indie! They are run for FREE from start to finish to
              support the local business community, and we want the experience
              to be fun and positive for everyone. As much as businesses want to
              win, please remain kind and considerate both to our hard-working
              staff and your fellow businesses.{' '}
            </p>

            <p>
              <strong style={{ color: 'rgb(231, 44, 94)' }}>
                WINNING THE FINALS{' '}
              </strong>
              <br />
              {AwardsContext === MuddyAwardsStates.VOTING_CLOSED
                ? `The winning business across each category will be invited to a celebratory Regional Winner’s Drinks Event. Every Regional Winner will also be automatically put forward for the National Finals, where the Muddy Stilettos Editors will crown the ‘Best of the Best’ in each category as a National Winner- a huge accolade to those winning the ultimate prize.`
                : generalSettings.siteId !== 24
                ? `Someone has to win, why not you?! The winning business across each category will be invited to a celebratory regional Winner’s Drinks Event. Every Regional Winner will also be automatically put forward for the National Finals, where the Muddy Stilettos Editors will crown the ‘Best of the Best’ in each category as a National Winner - a huge accolade to those winning the ultimate prize.`
                : `Someone has to win, why not you?! Every Regional Winner will also be automatically put forward for the National Finals, where the Muddy Stilettos Editors will crown the ‘Best of the Best’ in each category as a National Winner - a huge accolade to those winning the ultimate prize.`}
            </p>

            <p>
              For more information on the Muddy Awards, and for the most
              commonly asked questions, head to our{' '}
              <Link to="/awards/faqs" target="_blank" rel="noreferrer noopener">
                FAQs
              </Link>{' '}
              page. 
            </p>
            <TideB2B />
          </>
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsGetInvolved
